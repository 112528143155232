<template>
  <div id="wrapper">
    <div id="content_wrap" class="register_terms">
      <div id="top">
        <div id="nav">
          <a class="btn_back" @click="$router.go(-1)"><img src="../../assets/img/btn_back.svg" alt="뒤로가기"></a>
          <p class="current">월 멤버쉽 정기 구독 이용 안내</p>
          <a class="btn_back"></a>
        </div>
        <div id="top_info">
        </div>
      </div>
      <article class="scontainer">
        <section class="con1">
          <br>
          <p>
            1.	월 멤버쉽 프로그램은 정기구독 프로그램으로써 매월 자동 결제 됩니다.<br>
            2.	자동 결제는 결제일 기준으로 익월 동일 일자에 결제 됩니다. 단 익월에 동일 일자 없는 경우 익월 마지막 일자에 자동 결제가 진행 됩니다.<br>
            3.	자동 결제일 기준으로 카드결제 잔액부족 및 승인 거절 시 정기구독은 자동 해지 됩니다.<br>
            4.	결제 이후에는 결제일 기준으로 1개월간 세차장을 무제한으로 이용 가능합니다.<br>
            5.	월 멤버쉽은 각 차량별로 가입이 가능합니다.<br>
            6.	차량번호 변경은 불가능 합니다.<br>
            7.	월 멤버쉽 회원권을 타인 또는 타 차량에 양도는 불가능 합니다.<br>
            8.	자동 기기의 특성상 안전과 점검을 위해 한달에 1~2회 장비 점검 및 안전점검이 있을 수 있으니 공지에 따라 이용에 불편이 없으시길 바랍니다.<br>
            9.	멤버쉽 해지는 관리사무실 연락 또는 사용자웹에서 해지가 가능합니다.<br>
            10.	서비스 결제일로 부터 7일 이내에 세차 서비스를 이용한 횟수가 없는 경우에만 결제 취소가 가능합니다.<br>
          </p>
          <p>
            <br>
            본 이용 약관은 2023년 6월 28일부터 시행합니다.<br>

          </p>

        </section>
      </article>
    </div>
  </div>
</template>

<script>

export default {
};
</script>