<template>
  <div>
    <div id="content_wrap" class="notice_detail">
      <div id="top">
        <div id="nav">
          <!-- <a class="btn_back" href="./notice_list.html"><img src="../../assets/img/btn_back.svg" alt="뒤로가기"></a> -->
          <router-link to="/homebasic" class="btn_back">
            <img src="../../assets/img/btn_back.svg" alt="뒤로가기">
          </router-link>
          <p class="current">공지사항</p>
          <a class="btn_alarm" href="#"><img src="../../assets/img/btn_alarm.svg" alt="알람"></a>
        </div>
        <div id="top_info">
        </div>
      </div>
      <article class="scontainer">
        <section class="con1">
          <div class="notice_content">
            <div class="top">
              <h2 class="subject">내폰에 WASH DAY 세차앱 설치시 세차쿠폰(BASIC) 1개 지급</h2>
              <p class="date">2022.03.31</p>
            </div>
            <div class="scontent">
              <img src="../../assets/img/event01.jpg" alt="">
              <p>내폰에 WASH DAY 세차앱 설치시 세차쿠폰(BASIC) 1개 지급의 내용</p>
            </div>
          </div>
        </section>
      </article>
    </div>
    <FooterVue></FooterVue>
  </div>
</template>

<script>
import FooterVue from "../footer/FooterVue.vue";

export default {
  components: {
    FooterVue
  }
};
</script>