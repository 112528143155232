<template>
  <div id="wrapper">
    <div id="content_wrap" class="register_terms">
      <div id="top">
        <div id="nav">
          <a class="btn_back" @click="$router.go(-1)"><img src="../../assets/img/btn_back.svg" alt="뒤로가기"></a>
          <p class="current">개인정보 취급방침 이용악관</p>
          <a class="btn_back"></a>
        </div>
        <div id="top_info">
        </div>
      </div>
      <article class="scontainer">
        <section class="con1">
          <h3>1.	총직</h3><br>
          <h3>2.	개인정보의 수집 및 이용 목적</h3><br>
          <h3>3.	개인정보의 수집 항목</h3><br>
          <h3>4.	개인정보의 수집 방법</h3><br>
          <h3>5.	개인정보 처리의 위탁</h3><br>
          <h3>6.	개인정보의 보유 및 이용기간</h3><br>
          <h3>7.	장기 미이용 회원 개인정보 분리 보관</h3><br>
          <h3>8.	개인정보의 파기 절차 및 방법</h3><br>
          <h3>9.	개인정보의 자동 수집 장치(쿠키)의 설치, 운영 및 그 거부 방법</h3><br>
          <h3>10.	이용자의 권리와 의무</h3><br>
          <h3>11.	개인정보보호를 위한 기술적, 관리적 대책</h3><br>
          <h3>12.	의견 수렴 및 불만 처리</h3><br>
          <h3>13.	개인정보 관리책임자</h3><br>
          <h3>14.	고지의 의무</h3><br>
          <br>
          <h2>1.	총칙</h2>
          <br>
          <p>
            (1)	㈜엔티아이 (이하 ‘회사’라 합니다)는 ‘회원’의 개인정보를 매우 중요하게 생각합니다.<br>
            (2)	‘회사’는 ‘회원’의 개인정보를 적극적으로 보호하기 위하여 ‘개인정보처리방침’을 제정하고 이를 준수하고 있습니다.<br>
            (3)	‘회사’는 ‘개인정보처리방침’을 통해 ‘회원’의 어떤 개인정보 항목을, 어떤 목으로 수집되고 있으며, 어떻게 보호조치가 되고 있는지 알려드립니다.<br>
          </p>
          <h2>2.	개인정보의 수집 목적 및 이용 목적</h2>
          <br>
          <p>
            ‘회사’는 다음의 목적으로 ‘회원’의 개인정보를 수집하고 있습니다.<br>
            (1)	본인 확인 및 가입 의사 확인, 회원에 대한 고지 전달, 통합 ID 관리, 포인트/적립 사용 정산, 고객센터 운영, 불량회원 부정이용 방지 및 비인가 사용 방지<br>
            (2)	사은/판촉행사 등 각종 이벤트, 행사 관련 정보안내 및 제반 마케팅 활동, 당사 및 제휴사 상품/서비스 안내 및 권유, 혜택 및 서비스 개발을 위한 통계분석 및 연구조사.<br>

          </p>
          <h2>3.	개인정보의 수집 항목</h2>
          <br>
          <p>
            ‘회사’는 다음과 같이 ‘회원’의 개인정보를 수집하고 있습니다. 단, ‘회원’의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)와 주민등록번호는 법령상 근거 없이 불필요하게 수집하지 않습니다.<br>
            1)	필수항목<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-	공통: 아이디(차량번호, FLEET 사업자등록번호), 비밀번호, 이름, 전화번호, 이메일, 입금계좌정보<br>
            2)	서비스 이용 또는 사업처리 과정에서 생성/수집되는 정보<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-	IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 기기정보 등<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-	단말기기정보(모델명/단말식별번호/OS버전), 단말접속일시, PUSH토큰 등<br>
          </p>
          <h2>4.	개인정보의 수집방법</h2>
          <br>
          <p>
            당사는 다음의 각 호와 같은 방법으로 개인정보를 수집합니다.<br>
            1)	홈페이지(PC/모바일) 및 모바일 앱 이용<br>
            2)	사업자(FLEET)로 부터의 제공<br>
            3)	로그 분석 프로그램을 통한 생성 정보 수집<br>
            4)	쿠키(cookie)에 의한 정보 수집<br>
            5)	키오스크 이용에 의한 정보 수집<br>

          </p>
          <h2>5.	개인정보 처리의 위탁</h2>
          <br>
          <p>
            ‘회사’는 보다 나은 서비스 제공, 고객 편의 제공 등 원활한 업무 수행을 위하여 다음과 같이 개인정보 취급업무를 외부 전문업체에 위탁하여 운영하고 있습니다. 이 때, 회사는 업무 처리를 위하여 해당 업체의 업무 수행에 필요한 최소한의 정보만을 제공하고 있으며, 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 개인정보 위탁협약서 등 에 규정하고, 이를 관리/감독하고 있습니다.<br>
          </p>
          <h2>6.	개인정보의 보유 및 이용기간</h2>
          <br>
          <p>
            (1)	‘회사’는 개인정보의 처리 목적이 달성될 때까지 개인정보를 보유합니다. 단, 정보주체로부터 사전에 동의를 받은 경우 또는 상법 등 관련 법령에 의하여 보존할 필요성이 있는 다음의 경우에는 개인정보를 일정기간 동안 보유 합니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	계약 또는 청약철회 등에 관한 기록: 5년<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	대금 결제 및 재화 등의 공급에 관한 기록: 5년<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;다.	소비자의 불만 또는 분쟁처리에 관한 기록: 3년<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;라.	기타 고객의 동의를 받은 경우 동의를 받은 기간 까지<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;마.	회원의 동의를 얻어 회원탈퇴 시 30일간 개인정보 보유<br>
            (2)	고객 요구사항 처리 및 A/S 목적으로 회원탈퇴 후 30일간 ‘회원’의 개인정보를 보유합니다. 이는 고객의 실수 혹은 변심에 의해 탈퇴 신청 철회 시 이전 아이디 및 포인트, ‘회원’의 활동 내역 복구, 무분별한 회원탈퇴 및 재가입으로 인한 부정 이용 및 피해를 예방하기 위함 입니다.<br>
            단, 부정이용회원(쿠폰 부정 적립/사용 회원, 타인 명의를 이용한 적립/사용회원 등)의 경우 부정 이용 방지 및 비인가 사용 방지를 위해 회원가입 등의 제한조치 목적으로 차량번호 아이디는 3년간 보관합니다.<br>

          </p>
          <h2>7.	장기 미이용 회원 개인정보 분리 보관</h2>
          <br>
          <p>
            (1)	‘회사’는 관련 법령에 의거하여 휴면 회원의 개인정보를 접근이 불가능한 별도의 보관장치에 분리 보관합니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	시행 일자: 2023년 6월 28일<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	관련 법령: 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제 29조 및 동법 시행령 제 16조<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;다.	휴면 회원: 서비스를 1년 이상 이용하지 않은 회원<br>
            (2)	다음 조건을 모두 만족하는 경우에는 휴면 회원으로 전환 됩니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	거래이력이 1년 이상 없는 회원<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	서비스 홈페이지(PC/모바일웹, 앱 포함) 로그인 이력이 1년 이상 없는 회원<br>
            (3)	‘회사’는 휴면 전환 30일 전까지 휴면 예정 회원에게 별도 분리 보관되는 사실 및 휴면 예정일, 별도 분리 보관하는 개인정보 항목을 이메일, 서면, 모사전송, 전화 또는 이와 유사한 방법 중 어느 하나의 방법으로 이용자에게 알립니다. 해당 통지 수단에 대한 정보가 부재 또는 오류인 경우에는 홈페이지 공지사항 게시로 대신합니다.<br>
            (4)	휴면 상태에서는 서비스 이용이 모두 제한되며, 별도 휴면 해제 절차를 거친 후에 정상적인 서비스 이용이 가능합니다. 단, 휴면 상태 이전에 발급되었던 쿠폰은 해당 유효기간 만료 시 자동 소멸되므로 재발급이 불가합니다.<br>

          </p>
          <h2>8.	개인정보의 파기 절차 및 방법</h2>
          <br>
          <p>
            (1)	‘회사’는 보유기간의 경과, 개인정보의 처리 목적 달성 등 그 개인정보가 불필요하게 되었을 때에는 ‘6. 개인정보의 보유 및 이용기간’에 따라 해당 정보를 지체없이 파기 합니다.<br>
(2)	종이에 출력된 개인정보는 분쇄기로 분쇄 또는 소각하여 파기하고, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br>

          </p>
          <h2>9.	개인정보의 자동 수집 장치(쿠키)의 설치, 운영 및 그 거부 방법</h2>
          <br>
          <p>
            (1)	‘회사’는 개인화되고 맞춤화된 서비스를 제공하기 위하여 ‘회원’의 정보를 수시로 불러오는 ‘쿠키(Cookie)’를 사용합니다.<br>
            (2)	쿠키는 웹사이트를 운영하는데 이용되는 서버가 ‘회원’이 이용하는 브라우저에게 보내는 아주 작은 텍스트 파일로 ‘회원’이 이용하는 단말기(PC/스마트폰/태블릿 PC 등)의 하드디스크에 저장됩니다. 이후 ‘회원’이 웹사이트에 방문할 경우 웹사이트 서버는 ‘회원’이 이용하는 단말기의 하드디스크에 저장되어 있는 쿠키의 내용을 읽어 ‘회원’의 환경설정을 유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다.<br>
            (3)	‘회사’의 쿠기 사용 목적은 ‘회원’이 홈페이지에 접속을 하면 ‘회원’이 방문한 홈페이지의 각 서비스나 접속 빈도, 방문 시간 및 횟수 등을 분석하고 ‘회원’의 이용 형태, 이벤트 참여 정보 등을 파악하여 ‘회원’을 위한 마케팅이나 개인화 서비스의 제공 등을 위하여 사용합니다. 쿠키는 브라우저의 종료 시나 로그아웃 시 만료 됩니다.<br>
            (4)	‘회원’은 언제든지 쿠키의 설치를 거부하거나 삭제할 수 있습니다. 이에 따라 ‘회원’은 웹브라우저에서 옵션을 설정함으로서 모든 쿠키를 허용하거나, 쿠키가 저장될 때 마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수 도 있습니다. 단, 쿠키의 저장을 거부할 경우 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다. 쿠키 설치 허용 여부를 지정하는 방법 (Internet Explorer의 경우)은 다음의 각 호와 같습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	웹브라우저 상단의 [도구] 메뉴에서 [인터넷 옵션] <br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	[개인정보] 탭을 클릭<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;다.	[고급]을 클릭<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;라.	쿠키 허용 여부를 선택<br>

          </p>
          <h2>10.	이용자의 권리아 의무</h2>
          <br>
          <p>
            (1)	‘회원’은 다음 각호의 방법에 따라 ‘회원’ 본인의 개인정보를 열람 또는 정정/삭제/처리정지를 요구하거나 개인정보 제공에 관한 동의철회, 회원가입 해지를 요청 할 수 있습니다. ‘회사는 ‘회원’의 요구를 개인정보 관련 법령에 따라 지체없이 처리할 것입니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	홈페이지 (app.sparkpluswash.com)를 통한 ‘회원탈퇴’ 또는 ‘회원정보수정’<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	고객센터 (053-573-8008)를 통한 요청<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;다.	개인정보 관리책임자 및 담당자 (본 ‘개인정보취급방침’ 상 ‘13. 개인정보 관리책임자’ 조항 참조)에게 서면 또는 이메일로 요청<br>
            (2)	‘회원’은 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방할 수 있도록 협조해야 합니다. ‘회원’의 부정확한 정보 제공으로 발생하는 사고의 책임은 ‘회원’에게 있으며, 타인 정보의 도용 등 허위 정보를 입력할 경우 ‘회원’ 자격이 상실될 수 있습니다.<br>
            (3)	‘회원’은 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 비밀번호를 포함한 ‘회원’의 개인정보가 유출되지 않도록 조심하고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의하시기 바랍니다. 만약 이 같은 책임을 다하지 못하고 타인의 정보를 훼손할 시에는 ‘개인정보 보호법’ 등 관련 법력에 의해 처벌될 수 있습니다.<br>

          </p>
          <h2>11.	개인정보보호를 위한 기술적, 관리적 대책</h2>
          <br>
          <p>
            (1)	‘회사’는 ‘회원’의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 최선을 다하고 있으며, 다음의 각 호와 같은 기술적 대책을 강구하고 있습니다. <br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	‘회원’의 개인정보는 아이디와 비밀번호에 의해 관리되고 있으며, 비밀번호를 포함하는 파일 및 전송 데이터 등의 중요한 데이터는 암호화 등 별도의 보안기능을 통해 보호되고 있습니다. ‘회사’는 암호화 알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안 장치를 채택하고 있습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	‘회원’의 개인정보를 저장하고 관리하는 시스템은 방화벽과 침입차단시스템을 통하여 보호되며, 24시간 감시를 통하여 외부로부터의 위협에 대해 관리되고 있습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;다.	‘회사’는 백신프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.<br>
            (2)	‘회사’는 ‘회원’의 개인정보를 취급함에 있어 다음의 각 호와 같은 기술적 대책을 강구하고 있습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	‘회원’의 개인정보에 대한 접근 권한은 업무 역할에 따라 필요한 최소한의 인원으로 제한하고 있습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	‘회사’의 임직원이 업무를 함에 있어 ‘회원’의 개인정보를 보호하기 위해 지켜야 할 사내 규정을 마련하고 준수하고 있습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;다.	‘회사’의 임직원이 ‘회원’의 개인정보를 취급하기 이전에 ‘개인정보 취급서약서’를 통하여 ‘회원’의 개인정보 에 대한 정보 유출을 사전에 방지하고 사내 규정에 대한 이행사항 및 준수 여부를 감시하기 위한 내부 절차를 마련하고 있습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;라.	‘회사’ 임직원의 개인정보보호 실천을 위한 정기적인 개인정보보호 교육을 실시하고 있습니다.<br>
            (3)	‘회사’는 상기의 기술적, 관리적 대책에도 불구하고 ‘회원’ 개인의 실수나 사고 발생 당시의 기술로 예방, 조치를 할 수 없는 불가항력 사유로 인하여 발생한 개인정보의 분실, 도난, 누출 변조 또는 훼손에 대해서는 ‘회사’가 책임을 지지 않습니다.<br>

          </p>
          <h2>12.	의견 수렴 및 불만 처리</h2>
          <br>
          <p>
            (1)	‘회사’는 ‘회원’의 의견을 소중하게 생각하며, ‘회원’과의 원활한 의사소통을 위해 고객센터를 운영하고 있습니다. ‘회사’의 고객센터 연락처 및 상담 가능 시간은 다음의 각 호와 같습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	고객센터 전화번호: 053-573-8008<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	고객센터 전화상담 가능 시간: 평일 9시~18시 (12시 ~ 13시 점심시간)<br>
            (2)	기타 개인정보에 관한 상담이 필요한 경우 개인정보침해신고센터, 경찰청 사이버테러대응센터 등으로 문의하실 수 있습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	[개인정보침해신고센터] 전화번호: 1366 / URL: http://www.1366.or.kr<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	[경찰청 사이버테러대응센터] 전화번호: 1566-0112 / URL http://www.ctrc.go.kr<br>

          </p>
          <h2>13.	개인정보 관리 책임자</h2>
          <br>
          <p>
            ‘회사’는 수집된 ‘회원’의 개인정보를 관리를 위해 개인정보 관리책임자를 다음과 같이 지정하여 관리하고 있으며, 개인정보 관련 문의사항에 신속하고 성실하게 답변해 드리고 있습니다.<br>

              -	서비스담당 부서: 고객센터<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;전화번호: 053-573-8008<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;이메일: carwash@hanafmk.com<br>

              -	개인정보관리책임자 성명: 전학도<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;전화번호: 054-922-4020<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;이메일: hdjun@hanafmk.com<br>

          </p>
          <h2>14.	고지의 의무</h2>
          <br>
          <p>
            (1)	본 ‘개인정보취급방침’을 포함한 기타 개인정보보호에 대한 상세한 내용은 ‘회사’가 운영하는 홈페이지 첫 화면 하단에 공개함으로써 ‘회원’들이 언제나 용이하게 볼 수 있도록 조치하고 있습니다. 본 ‘개인정보취급방침’의 내용은 수시로 변경될 수 있으므로 홈페이지를 방문하실 때마다 이를 확인하시기 바랍니다.<br>
            (2)	본 ‘개인정보취급방침’이 관련 법령 및 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이 있을 경우 변경되는 ‘개인정보취급방침’을 시행하기 전에 홈페이지를 통해 변경 사유 및 내용 등을 공지 합니다.<br>
            <br><br><br>
            공고일자: 2022년 7월 7일<br>
            시행일자: 2022년 7월 7일<br>

          </p>

        </section>
      </article>
    </div>


<!--
    <aside>
      <div class="btn_cancel2">
         <a href="./register_basic02.html">취소</a
        <a @click="$router.go(-1)">취소</a>
      </div>
      <div class="btn_next2">
         <a href="./register_basic04.html">회원가입 완료</a>
        <router-link to="/registerBasic04">회원가입 </router-link>
      </div>
    </aside>
    -->
  </div>
</template>

<script>

export default {
};
</script>