<template>
  <div id="wrapper">
    <div id="content_wrap" class="register_terms">
      <div id="top">
        <div id="nav">
          <a class="btn_back" @click="$router.go(-1)"><img src="../../assets/img/btn_back.svg" alt="뒤로가기"></a>
          <p class="current">서비스 이용약관</p>
          <a class="btn_back"></a>
        </div>
        <div id="top_info">
        </div>
      </div>
      <article class="scontainer">
        <section class="con1">
          <h2>제 1장 총 칙</h2>
          <br>
          <p>
            <span class="title">제 1조 (목적)</span>
            1.	SPARK PLUS / ㈜엔티아이(이하 ‘회사’라고 합니다) 에서 운영하는 웹사이트 SPARK PLUS 사용자앱(app.sparkpluswash.com)(이하 "사용자앱"이라 합니다.)와 SPARK PLUS 키오스크(이하 “키오스크”라 합니다)에서 제공하는 세차 서비스 (이하 “서비스”라 합니다.)를 이용함에 있어 본 웹사이트 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.
          </p>
          <p>
            <span class="title">제 2조 (용어정의)</span>
            1.	본 약관에서 사용하는 용어는 다음과 같이 정의 합니다.<br>
            2.	“이용자”란 사용자앱 또는 세차장의 키오스크에 접속하여 이용하는 회원 및 비회원을 말합니다.<br>
            3.	“회원” 이란 사용자앱에 접속하여 본 약관에 따라 개인회원 또는 FLEET 회원으로 가입하여 사용자앱에서 제공하는 서비스를 받는자를 말합니다.<br>
            4.	 “비회원”이란 회원에 가입하지 않고 세차장에서 제공하는 서비스를 이용하는 자를 말합니다.<br>
            5.	“FLEET” 이란 사용자앱에 가입하는 사업자 또는 단체를 말하며, 개인사업자, 법인사업자, 유한회사, 비영리법인, 사단, 재단, 동호회 단체 등이 해당 됩니다.<br>
            6.	“월 멤버쉽” 이란 사용자앱 또는 키오스크에서 매월 정기구독 결제를 신청한 회원을 말합니다.<br>
            7.	“사용자 앱” 이란 회사가 모바일 및 휴대용 기기 등을 통하여 제공하는 앱을 말합니다.<br>
            8.	“키오스크” 이란 회사가 세차장장에서 오프라인으로 결제를 할 수 있도록 지원는 하드웨어 장치를 말합니다. <br>
            9.	“ID”라 함은 이용자가 회원가입당시 등록한 사용자의 “식별번호” (개인 차량번호 또는 FLEET ID)를 말합니다.<br>
          </p>
          <p>
            <span class="title">제 3조 (약관의 효력 및 변경)</span>
            1.	본 약관은 회사가 사용자앱에 게시하고, 회원가입 시 본 약관에 동의함으로써 그 효력이 발생합니다.<br>
            2.	사용자앱으로 회원가입 하지 않고 키오스크를 이용하는 이용자는 결제시 본 약관을 확인할 수 있도록 공시함으로써 효력이 발생 합니다.<br>
            3.	변경된 약관은 공시하거나 고지한 적용일로부터 효력이 발생 합니다.<br>
            4.	약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 사용자 앱에 적용일자 7일 이전부터 적용일자 전일까지 공지 합니다.<br>
            5.	회원이 변경된 약관 사항에 동의하지 않을 경우, 약관의 효력 발생 전일까지 서비스 이용을 중단하거나 회원 탈퇴를 할 수 있으며, 약관의 개정과 관련하여 효력 발생일 전일 까지 이의를 제기 하지 않는 경우에는 개정된 약관에 동의 한 것으로 간주 합니다.<br>
            6.	본 약관에 명시되지 않은 사항은 정보통신촉진법, 전자상거래등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망 이용촉진등에 관한 법률, 소비자보호법 등 기타 관계 법령에 규정되어 있을 경우에는 그 규정을 따르도록 합니다.<br> 

          </p>

          <h2>제 2장 회원 관리 및 서비스</h2>
          <br>
          
          <p>
            <span class="title">제 4 조 (이용 신청)</span>
            1.	이용 신청자가 회원가입 안내에서 본 약관과 개인정보정책에 동의하고 가입절차를 거쳐 ‘회원가입’ 버튼을 누르면 이용 신청을 할 수 있습니다.<br>
            2.	이용 신청자는 실제 정보를 사용해야 하며 개인회원의 경우 1대의 차량정보에 대하여 1개의 ID로 이용신청 할 수 있으며, FLEET의 경우 여러대의 등록된 차량이 1개의 ID로 이용신청 할 수 있습니다.<br>
            3.	실제 정보를 입력하지 않은 이용자는 법적인 보호를 받을 수 없으며, 서비스 이용에 제한을 받을 수 있습니다.<br>

          </p>
          <p>
            <span class="title">제 5 조 (이용신청의 승낙 및 유보)</span>
            1.	회사는 제 4 조에 따른 이용신청자에 대하여 서비스 이용을 승낙 할 수 있습니다.<br>
            2.	회사는 아래 사항에 해당하는 경우에 그 제한사유가 해소될 때까지 승낙을 유보할 수 있습니다.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	서비스 관련 설비에 여유가 없는 경우<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	기술상 지장이 있는 경우<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;다.	기타 회사 사정상 필요하다고 인정되는 경우<br>
            3.	회사는 아래 사항에 해당하는 경우에는 승낙을 하지 않을 수 있습니다.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	다른 사람의 명의를 사용하여 신청한 경우<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	이용자 정보를 허위로 기재하여 신청한 경우<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;다.	사회의 안녕질서 또는 미풍약속을 저해할 목적으로 신청한 경우<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;라.	기타 회사가 정한 이용신청 요건이 미비한 경우<br>
          </p>
          <p>
            <span class="title">제 6 조 (등록 정보의 이용)</span>
            1.	회사는 회원 등록정보를 집단적인 통계치 형태로 사용할 수 있습니다.<br>
            2.	회사는 관련 법령에 따른 경우를 제외하고는 회원의 동의 없이 회원 정보를 공개하지 않습니다.<br>
            3.	회사는 회원이 회사의 권리를 침해하거나 기타 불법행위를 하는 경우, 정당한 법 절차에 따라 회원의 정보를 이용할 수 있습니다.<br>
            4.	회사는 수사기관이 수사와 관련하여 정당한 법 절차에 따라 회원의 정보를 요구하는 경우에는 이에 응하여 회원의 등록 정보를 수사기관에 제공 할 수 있습니다.<br>
          </p>

          <p>
            <span class="title">제 7 조 (휴먼계정 관리)</span>
            1.	회사는 회원이 12개월 이상 로그인 하지 않으면, 휴면계정으로 간주하고 회사가 제공하는 서비스 이용을 아래와 같이 제한/상실 시킬 수 있습니다.<br>
            2.	12개월 이상 서비스에 로그인하지 않는 경우에는 휴면계정으로 별도 사용자앱 이용이 중단되며, 이후 다시 서비스를 이용하려면 본인확인 절차를 거쳐야 합니다.<br>
            3.	회사는 휴면계정 전환 30일 전 회원이 등록한 가장 최근의 연락처로 휴면계정 전환 예정 통지를 합니다.<br>
            4.	SPARK PLUS 회원은 제 2항에도 불구하고, 사용자앱에 12개월 이상 로그인 하지 않을 경우 휴면계정으로 구분됩니다.<br>
          </p>
          <p>
            <span class="title">제 8 조 (회원 탈퇴)</span>
            1.	회원은 탈퇴를 희망하는 경우, “MY” 페이지에서 “회원 탈퇴” 버튼을 사용하여 탈퇴 신청을 합니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	SPARK PLUS 일반회원의 경우 쿠폰 유효기간이 남아 있고, 월 멤버쉽 회원의 경우 사용 일수가 남아 있는 경우 카드의 활불 및 환급 등의 처리가 모두 완료된 다음에 탈퇴 할 수 있습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	탈퇴 시에는 발급된 쿠폰과 사용 일수가 모두 소멸되어 재가입 시 소멸된 쿠폰과 사용 일수는 복원되지 않습니다.<br>
            2.	회원 재가입은 회원이 가입 후 탈퇴, 재가입 등을 반복함으로써 회사가 제공하는 쿠폰, 이벤트 혜택 등 경제상의 이익을 취하거나 기타 명의도용 등의 불법적인 행위 등을 방지 하기 위하여, 회원 탈퇴를 한 날로부터 30일이 경과한 경우에 가능 합니다.<br>
            3.	회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을 제한 또는 정지 시킬 수 있고, 14일의 기간을 정하여 시정하거나 소명할 기회를 부여한 뒤 회원의 소명이 없거나 그 소명이 정당하지 아니한 경우 회원자격을 박탈할 수 있습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	타인의 회사 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	회원 가입시 허위 내용의 등록 및 타인의 정보 도용<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;다.	관계 법령을 위배하거나 미풍양속을 저해하고자 하는 목적으로 회원가입신청을 할 경우<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;라.	본 약관에 위반한 경우<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;마.	회사 및 기타 제3자의 권리를 침해하거나 도용하였을 경우<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;바.	회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;사.	구매 상품을 정당한 이유 없이 상습적으로 취소 또는 반품(환불)하는 등의 방법으로 회사의 업무를 현저히 방해하는 경우<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;아.	상품 구매 및 서비스 이용 시 정상적인 거래 범위를 현저히 이탈하여 오남용하는 경우<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;자.	기타 회원으로서의 자격을 지속시키는 것이 객관적으로 부적절하다고 판단되는 경우<br>
          </p>
          <p>
            <span class="title">제 9 조 (서비스의 중단)</span>
            1.	회사는 컴퓨터 등 정보통신 설비의 보수 점검 교체 및 고장, 통신의 두절,  기타 불가항력적 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단 할 수 있습니다.<br>
            2.	회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상하지 아니 합니다. 단 회사의 고의 또는 과실이 있는 경우에는 그러하지 아니 합니다. <br>
          </p>
          <p>
            <span class="title">제 10 조 (회원에 대한 통지)</span>
            1.	회사가 회원에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 사용자앱 공지사항, 문자 메세지, 모바일 애플리케이션 푸쉬, 메신저 알림톡  등으로 할 수 있습니다.<br>
            2.	회사는 회원 전체에 대한 통지의 경우 7일 이상 회사의 공지사항에 게시함으로써 제1항의 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.<br>
          </p>
          <p>
            <span class="title">제 11 조 (정보의 제공 및 광고의 게제)</span>
            1.	회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 문자 메세지, 모바일 애플리케이션 푸쉬, 메신저 알림톡 등의 방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 관련법에 따른 거래관련 정보 및 고객 문의 등에 대한 답변 등을 제외하고는 언제든지 수신 거절을 할 수 있습니다.<br>
            2.	제1항의 정보를 문자 메세지, 모바일 애플리케이션 푸쉬, 메신저 알림톡 등을 통하여 전송하려고 하는 경우에는 회원의 사전 동의를 받아서 전송합니다. 다만, 회원의 거래관련 정보 및 고객문의 등에 대한 회신에 있어서는 그러하지 않습니다.<br>
          </p>
          <p>
            <span class="title">제 12 조 (업무의 제휴)</span>
            1.	회사는 이용자의 편의를 도모하고 효과적인 서비스의 일정 부분에 대해 다른 회사와 제휴할 수 있습니다.<br>
            2.	제휴 회사가 서비스와 관련하여 진행한 활동은 회사가 직접 시행한 활동에 준하는 효력이 있습니다.<br>
          </p>
          <p>
            <span class="title">제 13 조 (쿠폰)</span>
            1.	쿠폰의 구체적인 운영 방침이나 활용 방법 및 혜택은 회사의 마케팅 정책에 따라 변경될 수 있으며, 이에 대해서는 별도의 방법으로 사전에 안내해 드립니다. <br>
            2.	쿠폰으로 결제 시 회사에서 정한 기준에 따라 할인이 적용 될 수 있습니다.<br>
          </p>

          <h2>제 3장 월 멤버쉽 정기 구독 서비스</h2>
          <br>
          <p>
            <span class="title">제 14 조 (적용의 범위)</span>
           본 장의 규정은 SPARK PLUS 월 멤버쉽 정기 구독 서비스를 이용하고자 하는 모든 이용자에게 그 효력이 발생합니다.<br>
          </p>
          <p>
            <span class="title">제 15 조 (월 멤버쉽 서비스 이용에 관한 총칙)</span>
            1.	이용의 제한<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	회원은 4가지의 월 멤버쉽 서비스를 가입 할 수 있습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	회사는 다음 각 호에 해당하는 경우 이용자에게 멤버쉽 가입을 제한하거나 중단할 수 있습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)	운영 관련 설비의 장애 및 보수, 정전, 이용량의 폭주 등 기술상 서비스를 제공 할 수 없는 경우<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)	이용자가 타인 명의로 서비스를 이용하는 경우<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)	이용자가 회사의 페이 서비스 정책 또는 본 약관을 위반하여 서비스를 이용하고자 하는 경우<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)	이용자가 페이 서비스의 시스템을 무단으로 침범하는 경우<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)	기타 회사가 별도로 정하여 공지한 요건을 충족하지 않는 경우<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;다.	본 조에 따라 월 멤버쉽 서비스 제한이나 중단이 있을 때에는 회사가 제 10 조에서 정한 방법으로 회원에게 해당 내용을 통지 합니다. 다만, 회사가 통제할 수 없는 사유로 페이 서비스가 중단(회사의 고의, 과실이 없는 디스크 장애, 시스템 다운 등)되는 등 미리 통지 할 수 없는 상황이 발생했을 때에는 그러하지 않습니다.<br>
            2.	월 멤버쉽 서비스의 이용시간<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	월 멤버쉽 서비스의 이용은 회사가 정한 휴일을 제외한 08:00 ~ 22:00 이용을 원칙으로 합니다. 다만, 회사의 업무나 기술상의 이유로 월 멤버쉽 서비스가 일시 중지될 수 있고, 운영상의 목적으로 회사가 정한 기간에는 월 멤버쉽 서비스가 일시 중지될 수 있습니다. 이때 회사는 미리 해당 내용을 공지하며, 부득이하면 사후에 통보할 수 있습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	회사는 월 멤버쉽 서비스를 일정 범위로 나누어 범위 별로 이용할 수 있는 시간을 정할 수 있으며, 이 경우 그 내용을 공지 합니다.<br>
            3.	월 멤버쉽 서비스 이용에 대한 이용자의 의무와 책임<br>
            회사가 정상적으로 월 멤버쉽 서비스를 제공한 경우 월 멤버쉽 서비스 이용 시 발생하는 개인 간의 모든 민.형사상 책임은 이용자에게 있습니다.<br>
            4.	발행 및 지급보증<br>
            월 멤버쉽 서비스는 전자상거래(결제 수단) 보증보험증권에 가입되어 있습니다.<br>
          </p>
          <p>
            <span class="title">제 16 조 (취소 등)</span>
            1.	월 멤버쉽 서비스 결제 취소<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	이용자는 월 멤버쉽 서비스 최초 결제일로부터 7일 이내 결제 영수증을 지참하고, 세차 서비스를 이용한 횟수가 없는 경우에만 결제 취소를 요청 할 수 있으며, 회사는 최초 결제 금액의 100%를 원 결제수단의 승인 취소 또는 환급으로 취소합니다. 선물 결제 취소의 기한은 상품의 종류와 특성 그리고 회사의 사저에 따라 달리 정할 수 있습니다. 
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	이용자가 1호에 해당하지 않는 결제 취소를 요청을 한 경우에는 회사는 결제금액을 반환하지 않으며, 월 멤버쉽 서비스만 즉시 해지합니다.
            2.	면책 사항
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 월 멤버쉽 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제 됩니다.
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	회사는 이용자의 귀책사유로 인한 월 멤버쉽 서비스의 이용 장애에 대하여 책임을 지지 않습니다.
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;다.	회사는 다음 각 호에서 예시하는 바와 같이 이용자가 불법 또는 부정한 방법으로 월 멤버쉽 서비스를 이용하거나 과실에 해당하는 행위를 하여 이용자 상호 간 또는 이용자와 제3자 상호 간에 분쟁이 발생하였을 때 회사의 책임을 주장하는 자가 회사의 귀책사유를 객관적으로 입증하지 않는 한 이에 개입할 의무가 없으며 분쟁으로 발생하는 손해를 배상할 책임이 없습니다.
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)	타인의 휴대전화(분실된 전화 포함)를 이용하거나 번호를 무단으로 사용하여 월 멤버쉽 서비스를 이용한 경우
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)	이용자가 월 멤버쉽 서비스를 불법 할인 등의 목적으로 구매하여 제3자 또는 수신자에게 전송(판매)하는 경우
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)	이용자가 계좌 정보 등 환불 관련 정보를 잘못 입력하여 환불 오류가 발생한 경우
          </p>
          <p>
            <span class="title">제 17 조 (계약 당사자의 의무)</span>
            1.	회사의 의무<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	회사는 월 멤버쉽 서비스와 관련한 이용자의 불만사항이 접수되면 이를 신속히 처리해야 하며, 신속한 처리가 어려운 경우 그 사유와 처리 일정을 월 멤버쉽 서비스 화면에 게시하거나 휴대전화 문자 메세지, 모바일 애플리케이션 푸쉬, 메신저 알림톡 등을 통하여 해당 이용자에게 통지 합니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	회사가 제공하는 월 멤버쉽 서비스로 인하여 이용자에게 손해가 발생 하였을 때 그러한 손해가 회사의 고의나 과실로 인하여 발생 하였다면 회사가 그 책임을 부담하며, 책임의 범위는 통상 손해에 한합니다.<br>
            2.	이용자의 의무<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	이용자는 월 멤버쉽 서비스를 이용할 때 다음 각 호의 행위를 해서는 안됩니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)	부정하게 월 멤버쉽 서비스에 접속하여 사용하는 행위<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)	서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙없이 복제 또는 유통하거나 상업적으로 이용하는 행위<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)	회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)	공공질서와 미풍양속에 위반되는 내용 등을 타인에게 유포하는 행위<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)	월 멤버쉽 서비스 운영을 고의로 방해하는 행위<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6)	월 멤버쉽 서비스에 게시된 정보를 변경하는 행위<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(7)	기타 불법적이거나 부당한 행위<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	이용자는 관계 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 해서는 안 됩니다.<br>
            3.	회원 및 비회원의 의무<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	회원 및 비회원은 관계 법령, 약관, 서비스 이용안내 및 월 멤버쉽 서비스상에 공지한 주의사항, 회사가 월 멤버쉽 서비스 이용과 관련하여 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 해서는 안됩니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	회원 및 비회원은 본 약관에서 별도로 정하거나 회사의 분명한 동의가 있는 때가 아니면 월 멤버쉽 서비스 이용 권한을 타인에게 양도, 증여 또는 이용하게 하거나 이를 담보로 제공 할 수 없습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)	월 멤버쉽 서비스 이용신청 또는 변경 시 거짓된 정보를 기재하거나 타인의 개인정보를 이용하는 행위<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)	월 멤버쉽 서비스 이용 중 습득한 게시물 또는 정보를 상업적 목적으로 이용하거나 출판, 방송 등을 통하여 제3자에게 노출하는 행위. 다만, 공익 목적을 위하여 필요한 경우 사전에 회사의 동의를 얻어야 함.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)	회사의 월 멤버쉽 서비스 운영이나 다른 이용자의 페이 서비스 이용을 방해하는 행위와 자신을 회사의 운영진, 직원 또는 관계자라라고 속이는 행위<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)	월 멤버쉽 서비스를 제3자와 유상으로 거래하거나 현금으로 전환하는 행위<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)	기타 불법적이거나 회사에게 피해를 발생시키는 행위<br>
          </p>

          <h2>제 4장 재화의 주문 및 결제 관련</h2>
          <br>
          <p>
            <span class="title">제 18 조 (결제 방법)</span>
            이용자는 회사에서 판매하는 재화 및 서비스에 대하여 신용카드, 현금, 간편 결제 등의 각종 결제 수단을 이용하여 결제 할 수 있습니다.<br>
            1.	회사는 이용자의 구매 및 결제 신청이 있는 경우 이용자에게 구매 및 결제를 확인 할 수 있도록 사용자앱 또는 키오스크를 통해 구매 영수증을 제공합니다.<br>
            2.	구매영수증을 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 구매영수증을 받은 후 즉시 구매신청 변경 및 결제 취소를 요청 할 수 있으며, 회사는 재화 및 서비스 이용 전에 이용자의 요청이 있는 경우에는 회사의 취소 및 환불 규정에 의거하여 필요한 조치를 합니다.<br>
          </p>
          <p>
            <span class="title">제 19 조 (취소 및 환불 규정)</span>
            1.	회사는 이용자가 구매/결제한 재화 및 서비스 등이 품절 또는 설비 고장 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 서비스 이용 전에 재화 및 서비스 등의 대금을 받은 경우에는 대금을 받은 날로부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.<br>
            2.	세차 상품 및 쿠폰 구매에 대한 취소 및 환불 규정은 부록_취소/환불 정책에 따릅니다.<br>
          </p>

          <h2>제 5장 기 타</h2>
          <br>
          <p>
            <span class="title">제 20 조 (전자영수증의 발행 및 거래 내역의 확인)</span>
            1.	회사는 사용자앱 및 키오스크를 통한 거래한 내역 및 그 증빙에 대하여, 전자적 형태인 전자 영수증으로 우선 발급하며, 이용자는 이에 동의 합니다.<br>
            2.	제1항에 포함되지 않는 거래에 해당하는 경우에는 영수증이 별도로 발행되지 않을 수 있습니다.<br>
            3.	회원은 사용자 앱의 “전자영수증” 구매내역 메뉴를 통해 회원의 거래내역 등을 확인 할 수 있습니다.<br>
            4.	회사는 다음 각 호에 해당하는 경우, “전자영수증” 서비스 전부 또는 일부를 제한하거나 중지할 수 있습니다. <br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가.	서비스를 위한 설비 보수, 점검, 교체 등의 사유로 일시적인 서비스 장애가 발생한 경우<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나.	제반 설비의 장애 또는 이용 폭주 등의 사유로 정상적인 서비스 이용이 어려운 경우<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;다.	천재지변 등 불가항력적 사유가 발생한 경우<br>
            5.	회사는 관련 법령에 의거하여 회원과의 거래내역을 거래가 발생한 날로부터 최대 5년간 보관하며, 해당 기간 동안 회원은 회사가 제공하는 사용자앱을 통하여 열람 및 확인 할 수 있습니다.<br>
          </p>
          <p>
            <span class="title">제 21 조 (약관의 해석)</span>
            본 약관에 명시되지 않은 사항 또는 본 약관 해석상 다툼이 있는 경우에는 이용자와 회사의 합의에 의하여 결정 합니다. 다만, 합의가 이루어지지 않은 경우에는 관계법령 및 거래 관행에 따릅니다.<br>
          </p>
          <p>
            <span class="title">제 22 조 (책임 제한)</span>
            1.	회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없거나 회사의 귀책사유가 없는 경우에는 책임을 지지 않습니다.<br>
            2.	회사는 서비스 이용과 관련하여 이용자의 고의 또는 과실로 인하여 발생한 손해에 관하여 책임을 지지 않습니다.<br>
          </p>
          <p>
            <span class="title">제 23 조 (손해 배상)</span>
            1.	이용자가 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 이용자는 회사에 발생하는 손해를 배상해야 합니다.<br>
            2.	서비스를 이용하면서 불법행위나 본 약관을 위반하는 행위를 한 이용자로 말미암아 회사가 해당 이용자 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는다면 해당 이용자는 자신의 책임과 비용으로 회사를 면책 시켜야 하며, 회사가 면책되지 못한 경우 당해 이용자는 그로 인하여 회사에 발생한 손해를 배상해야 합니다.<br>
          </p>
          <p>
            <span class="title">제 24 조 (관할 법원)</span>
            본 약관과 관련하여 회사와 이용자 사이에 발생한 분쟁에 관한 소송은 회사의 소재지를 관할하는 법원 또는 민사소송상의 관할법원을 관할법원으로 합니다.<br>
          </p>
          
        </section>
        
      </article>
    </div>


<!--
    <aside>
      <div class="btn_cancel2">
         <a href="./register_basic02.html">취소</a
        <a @click="$router.go(-1)">취소</a>
      </div>
      <div class="btn_next2">
         <a href="./register_basic04.html">회원가입 완료</a>
        <router-link to="/registerBasic04">회원가입 </router-link>
      </div>
    </aside>
    -->
  </div>
</template>

<script>

export default {
};
</script>